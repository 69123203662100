import React, {useMemo} from "react";
import DesignMiniature from "./DesignMiniature";

const DesignsViewList = (props) => {

    const views_list = useMemo(() => props.designs.map(
        (url, idx) => {
            const active = (props.activeDesign === url) ? true : false;
            return <DesignMiniature
                url={url}
                alt={"Design miniature " + (idx + 1)}
                active={active}
                key={`${url}`}
                number={idx}
                activeDesignHandler={props.activeDesignHandler}
            ></DesignMiniature>
        }
    )
        , [props.designs[0], props.activeDesign])

    return <div className="space-y-4 sm:pr-6 sm:pl-6 lg:pb-6 lg:col-span-2 lg:pr-0 lg:pl-0 lg:h-screen">
        <p className="text-section">Designs</p>
        <div className={`
                    sm:flex sm:flex-row lg:flex-col sm:flex-nowrap lg:flex-none sm:space-x-2 sm:overflow-x-auto 
                    lg:overflow-y-auto lg:space-x-0 lg:h-[calc(100%-154px)] lg:overflow-x-hidden gap-y-2 pb-4
                `}>
            {views_list}
        </div>
    </div>

}

export default DesignsViewList
import React, { useContext, useMemo, useState } from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";

/*Context*/
import { DesignsViewerContext } from "../context/DesignsViewerContext";

/*Fetch and format data functions */
import { GetDesignsQuery, designsDataFormatter, getDesignsQueryArray, designsCardsFormatter } from "../functions/getDesignsQuery"

/*Components*/
import CardsContainer from "./CardsContainer";
import DesignsModal from "./DesignModal";

const linkAnimations = {
    initial: {
        opacity: 0,
        x: -50,
        transition: {
            delay: 0.125,
            ease: "easeInOut",
            type: "spring",
            duration: 0.3,
            stiffness: 80,
            mass: 0.8
        }
    },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            delay: 0.125,
            ease: "easeInOut",
            type: "spring",
            duration: 0.3,
            stiffness: 80,
            mass: 0.8
        }
        

    },

    reHover: {
        transition:{
            delay: 0.025,
            type: "tween",
            duration: 0.01
        }
        

    },
    hover:{
        scale: 1.02,
        transition:{
            type: "tween",
            duration: 0.3
        }
    }
}


const DesignsContainer = (props) => {
    const { cardsMobile, cardsTablet, cardsDesktop, limited, title } = props
    const [showDesignsModal, setShowDesignsModal] = useState(false);
    const [activeDesign, setActiveDesign] = useContext(DesignsViewerContext)

    const queryDataRaw = GetDesignsQuery()
    const queryDataArray = useMemo(
        () => getDesignsQueryArray(queryDataRaw),
        []
    )
    const cardsData = useMemo(
        () => designsCardsFormatter(queryDataArray),
        []
    )

    const designsDataFormatted = useMemo(
        () => designsDataFormatter(queryDataArray)
        , [])

    return <section id="designs">

        <CardsContainer
            title={title}
            cardsData={cardsData}
            cardsAction={({ ID }) => {
                setShowDesignsModal(true)
                setActiveDesign(ID)
            }}
            cardsMobile={cardsMobile}
            cardsTablet={cardsTablet}
            cardsDesktop={cardsDesktop}
            limited={limited}
        ></CardsContainer>
        {
            (limited)
                ? <motion.div 
                    className="text-secondary dark:text-complement-2 text-URL mt-4"
                    variants={linkAnimations}
                    initial="initial"
                    animate="animate"
                    exit="initial"
                    >
                    <div className="mt-16">
                        <Link to="/designs"> Wanna see all the designs?</Link>
                        <br></br>
                        <Link to="/designs"> click here</Link>
                    </div>
                </motion.div>
                : null
        }
        <DesignsModal
            designsData={designsDataFormatted}
            activeModal={showDesignsModal}
            setActiveModal={setShowDesignsModal}

        >
        </DesignsModal>
    </section>

}

export default DesignsContainer;
import React from "react"
import { motion } from "framer-motion"

import { ThemeContextProvider } from "../context/ThemeContext"
import { WindowResizeContextProvider } from "../context/WindowResizeContext"
import { DesignsViewerContextProvider } from "../context/DesignsViewerContext"

import DesignsContainer from "../components/DesignsContainer"

import ContactSection from "../components/ContactSection"
import Navbar from "../components/Navbar"
import TypingAnimation from "../components/TypingAnimation"
import useDarkTheme from "../customHooks/useDarkTheme"
import ThemeHelmet from "../components/ThemeHelmet"

const DesignsPage = () => {

	const [darkTheme, setdarkTheme] = useDarkTheme()

	const sectionAnimations = {
		initial: {
			x: -200,
			opacity: 0,
		},
		animate: {
			x: 0,
			opacity: 1,
			transition: {
				ease: "easeInOut",
				type: "spring",
				stiffness: 80,
				mass: 0.5,
				duration: 0.1,
				delayChildren: 0.2,
			}

		},
	}

	const titleAnimation = {
		initial: {
			opacity: 0,
			x: -50
		},
		animate: {
			opacity: 1,
			x: 0,
			transition: {
				delay: 0.15,
				type: "spring",
				stiffness: 80,
				mass: 1,
				duration: 0.5,
			}

		},
	}

	const introTextAnimation = {
		initial: {
			opacity: 0,
			x: -50

		},
		animate: {
			x: 0,
			opacity: 1,
			transition: {
				delay: 0.3,
				type: "spring",
				stiffness: 80,
				mass: 0.8,
				duration: 0.5,
			}

		},
	}

	const darkModeClass = (darkTheme) ? "dark" : ""


	return (
		<ThemeContextProvider value={{ darkTheme, setdarkTheme }}>
			<ThemeHelmet darkTheme={darkTheme}></ThemeHelmet>
			<WindowResizeContextProvider>

				<main
					className={`text-black-A text-base ${darkModeClass}`}
					id="home"
				>
					<Navbar activePage="Designs" setDarkMode={setdarkTheme} darkMode={darkTheme}></Navbar>
					<motion.section className="dark:text-white-B  sm:ml-6 sm:mr-6 sm:pt-[108px] sm:pb-16 md:ml-10 md:mr-10  
						lg:mr-[78px] lg:ml-[78px] xlg:mr-[calc(calc(100%-1280px)/2)] xlg:ml-[calc(calc(100%-1280px)/2)]"
						variants={sectionAnimations}
						initial="initial"
						animate="animate"

					>
						<section className="grid sm:grid-cols-6 sm:gap-4 md:grid-cols-8 md:gap-8 lg:grid-cols-12">
							<section className="col-span-6">
								<motion.h2 variants={titleAnimation} className="text-hero-title text-primary dark:text-white-B mb-4">Designs</motion.h2>
								<motion.p variants={introTextAnimation}>Here you can find the <span className="font-semibold text-primary dark:text-white-B">designs</span> I have worked on.
									If you are interested in any such work,
									<span className="font-semibold text-primary dark:text-white-B whitespace-pre-wrap"> you can drop me an email.</span>
								</motion.p>
								<motion.p className="mt-6" variants={introTextAnimation}>
									<a className={((darkTheme) ? "text-complement-2" : "text-secondary") + " sm:text-3xl text-hero-cta"} href="mailto:hello@dicruzcar.xyz">
										<TypingAnimation
											phrases={["hello@dicruzcar.xyz"]}
											loop={false}
											speed={25}
											wait={12}
											tikDuration={500}
											delay={500}
											isActive={true}
										></TypingAnimation>
										&nbsp;
									</a>
								</motion.p>
							</section>
						</section>
						<section className="mt-16">
							<DesignsViewerContextProvider>
								<DesignsContainer
									cardsMobile={3}
									cardsTablet={4}
									cardsDesktop={5}
									limited={false}
									title={null}
								></DesignsContainer>
							</DesignsViewerContextProvider>
						</section>
						<section className="mt-32">
							<ContactSection darkMode={darkTheme}></ContactSection>
						</section>
					</motion.section>

				</main>
			</WindowResizeContextProvider>
		</ThemeContextProvider>

	)
}

export default DesignsPage

export const Head = () => <title>dicruzcar - Designs</title>

import { useStaticQuery ,graphql } from "gatsby"

const GetDesignsQuery = () => useStaticQuery(graphql`query GetDesigns {
    allSanityPost(
      filter: {priority: {}, categories: {elemMatch: {title: {eq: "Designs"}}}}
      sort: {fields: priority}
    ) {
      nodes {
        categories {
          title
        }
        priority
        title
        preview
        images
        description
        id
      }
    }
  }`)

const designsDataFormatter = (data) => { 
    return data.map(
        (elm) =>{
            return{
                name: elm.title,
                ID: elm.id,
                description: elm.description,
                url: elm.preview,
                designs: elm.images
            }
        } 
    )
}

const getDesignsQueryArray = (data) => data.allSanityPost.nodes

const designsCardsFormatter = (data) => {
    return data.map(
        (elm) => {
            return {
                title: elm.title,
                description: elm.description,
                preview: elm.preview,
                buttonText: "View designs",
                ID: elm.id,
                category: elm.categories[0].title
            }
        } 
    )
}

export {GetDesignsQuery, designsDataFormatter, getDesignsQueryArray, designsCardsFormatter}
import React, { useState, useContext, useMemo, useEffect, useCallback } from "react"
import { motion } from "framer-motion"

import { WindowResizeContext } from "../../context/WindowResizeContext"
import { ThemeContext } from "../../context/ThemeContext"

const viewerAnimation = {
    initial: {
        x: -200,
        opacity: 0,
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            delay: 0.15,
            ease: "easeInOut",
            type: "spring",
            duration: 0.5,
            stiffness: 80,
            mass: 0.5
        }
    }
}

const DesignView = (props) => {
    const [imageLoaded, setImageLoaded] = useState(false)
    const { darkTheme } = useContext(ThemeContext)
    const { dimentions } = useContext(WindowResizeContext)
    const [viewHeight, setViewHeight] = useState(dimentions.height)

    const img = useMemo(() => {
        return new Image();
    }, []);

    useEffect(() => {
        setImageLoaded(false)
        img.src = props.url;
        img.onload = () => {
            setImageLoaded(true)
        };
    }, [setImageLoaded, props.url, img])

    const getViewHeight = useCallback((element) => {
        if (element !== null) {
            const padding = 14;
            const h = document.documentElement.clientHeight - element.getBoundingClientRect().top - padding
            setViewHeight(h)
        }
    }, [dimentions.height, imageLoaded])


    const placeHolderClassName = (imageLoaded)
        ? "opacity-0"
        : `flex-grow bg-white-B dark:bg-black-A rounded-[10px] opacity-100 transition-[opacity] duration-300 ease-in`

    const imageClassName = (imageLoaded)
        ? `h-fit dark:brightness-[0.9] opacity-100 transition-[opacity] duration-300 ease-in pb-4`
        : `hidden opacity-0 `

    return <div className={`
            sm:flex flex-row sm:flex-grow ${(!imageLoaded) && "animate-pulse"} 
            overflow-y-auto lg:flex-none md:place-content-center sm:content-start 
            sm:pr-6 sm:pl-6 lg:pr-3 lg:pl-0 lg:col-span-10
        `} ref={getViewHeight}
        style={{
            height: `${viewHeight}px`
        }}
    >
        <div className={placeHolderClassName}></div>
        <motion.div
            className={imageClassName + " relative overflow-y-hidden"}
            src={props.url}
            alt="Design View"

            variants={viewerAnimation}
            initial="initial"
            animate="animate"
            exit="initial"
        >
            <img
            className={`overflow-y-hidden rounded-[10px] sm:pt-[0] sm:pl-3 sm:pr-3 md:pt-2 md:pr-4 md:pl-4 ${(darkTheme) ? "image-as-dark-shadow" : "image-as-shadow" }`}
            src={props.url}
            alt="Design View"
            />
            
            <div className="sm:pl-[2px] sm:pr-1 sm:pb-1 md:pb-4 md:pr-4 md:pl-2">
                <img
                className="relative overflow-y-hidden rounded-[10px] "
                src={props.url}
                alt="Design View"
                />
            </div>
        </motion.div>
    </div>
}

export default DesignView
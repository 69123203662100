import React, { useState } from "react";

const DesignsViewerContext = React.createContext();

const DesignsViewerContextProvider = (props) => {
    const [activeDesign, setActiveDesign] = useState("");
    return <DesignsViewerContext.Provider value={[activeDesign, setActiveDesign]}>
            {props.children}
    </DesignsViewerContext.Provider>
}

export {DesignsViewerContextProvider, DesignsViewerContext};
import React, {useContext, useState, useMemo} from "react";
import DesignsViewList from "./DesignViewList";
import DesignView from "./DesignView";

import { DesignsViewerContext } from "../../context/DesignsViewerContext";

const DesignsViewer = (props) => {
    const designsData = props.designsData
    const [activeDesign] = useContext(DesignsViewerContext)
    const [activeURL, setActiveURL] = useState()

    const designs = useMemo(
        () => {
            const r = designsData.find(
                elm => elm.ID === activeDesign
            )
            const des = (typeof r !== "undefined") ? r.designs : designsData[0].designs
            setActiveURL(des[0])
            return des
        }, [designsData[designsData.length - 1].ID, activeDesign]

    )

    return <div className={`
            sm:flex sm:flex-col sm:flex-grow sm:flex-nowrap sm:space-y-2 
            lg:flex-none lg:space-y-[41px] lg:grid lg:grid-cols-12 lg:gap-[26px] 
            lg:ml-[78px] lg:mr-[78px] lg:h-[calc(100vh-163px)]
        `}>
        <DesignsViewList designs={designs} activeDesignHandler={setActiveURL} activeDesign={activeURL} />
        <DesignView url={activeURL} />
    </div>

}


export default DesignsViewer
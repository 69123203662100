import React, {useMemo, useContext} from "react";
import Modal from "./Modal";
import DesignsViewer from "./DesignsViewer/DesignsViewer";
import { DesignsViewerContext } from "../context/DesignsViewerContext";

const DesignsModal = (props) => {
    const {activeModal, designsData, setActiveModal} = props
    const [activeDesign] = useContext(DesignsViewerContext)
    const triggerOnClose = () => setActiveModal(false);
    const designs_content = <DesignsViewer designsData={designsData}/>
    const designName = useMemo(
        () =>{
            const r = designsData.find(
                elm => elm.ID === activeDesign                    
            )
            const des = (typeof r !== "undefined") ? r.name : ""
            return des
        }, [designsData[designsData.length -1].ID, activeDesign]
            
    )    
    return  <Modal 
                title={designName} 
                content={designs_content} 
                html_id="1" 
                addClass="designs-modal"
                activeModal={activeModal} 
                closeHandler={triggerOnClose}
            /> 
}

export default DesignsModal
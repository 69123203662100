import React, { useMemo, useState, useContext, useEffect } from "react";
import { motion } from "framer-motion";

import { ThemeContext } from "../../context/ThemeContext";

const DesignMiniature = (props) => {
    const [imageLoaded, setImageLoaded] = useState(false)
    const { darkTheme } = useContext(ThemeContext)

    const miniatureAnimation = useMemo(() => { return{
        initial: {
            x: -200,
            transition: {
                ease: "easeInOut",
                type: "tween",
                duration: 0.5
            }
        },
        animate: {
            x: 0,
            scale: 1,
            transition: {
                delay: 0.1 + 0.025 * props.number,
                ease: "easeInOut",
                type: "spring",
                duration: 0.3,
                stiffness: 80,
                mass: 0.5
            }
        },
        tapIn: {
            scale: 0.965,
            transition: {
                ease: "easeInOut",
                type: "spring",
                duration: 0.1,
                stiffness: 80,
                mass: 1
            }
        },
    }}, [props.number])
    
    useEffect(() => {
        const img = new Image();
        img.src = props.url;
        img.onload = () => {
            setImageLoaded(true)
        };
    }, [props.url]);


    const placeHolderClassName = (imageLoaded)
        ? "hidden"
        : `sm:w-[142px] sm:h-[80px] lg:min-w-full lg:pt-[28%] lg:pb-[28%] lg:pl-[50%] lg:pr-[50%] lg:box-border bg-white-B dark:bg-black-A rounded-[5px]`

    const imageClassName = (imageLoaded)
        ? `sm:h-[80px] sm:min-w-[32px] sm:w-fit lg:min-w-full lg:h-fit rounded-[5px]}`
        : `hidden`

    const activeClassName = (props.active && imageLoaded)
        ? `relative ${darkTheme ? "bg-secondary-dark" : "bg-gradient-1"} p-[6px] rounded-[10px] flex-none`
        : `relative `

    const onClickItem = () => {

        if (!props.active) props.activeDesignHandler(props.url)

    }

    return <motion.div className={`
            ${(!imageLoaded) ? "animate-pulse" : ""} pb-3 pr-3 sm:min-w-fit lg:w-full
            `} onClick={onClickItem}

        variants={miniatureAnimation}
        initial="initial"
        animate="animate"
        exit="initial"
        whileTap="tapIn"

    >
        <div className={activeClassName}>
            <img className={imageClassName + " pl-2 z-[-10]" + (darkTheme ? " image-as-dark-shadow" :  " image-as-shadow") } src={props.url} alt={props.alt} />
            <img className={imageClassName + " relative rounded-[5px] dark:brightness-[0.90]"} src={props.url} alt={props.alt} />
        </div>
        <div className={placeHolderClassName}></div>

    </motion.div>
}

export default DesignMiniature
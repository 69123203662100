import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../context/ThemeContext";

const modalAnimations = {
    initial:{
        x: "-100vw",
        opacity: 0,
        transition: {
            ease: "easeInOut",
            type: "tween",
            duration: 0.5
        }
    },
    animate:{
        x:0,
        opacity: 1,
        transition: {
            ease: "easeInOut",
            type: "spring",
            duration: 0.3,
            stiffness: 80,
            mass: 0.5
        }
    }
}

const Modal = (props) => {
    const { darkTheme } = useContext(ThemeContext)
    const activeModal = props.activeModal
    const closeHandler = props.closeHandler

    const closeModal = useCallback(() => {
        closeHandler(false)
    }, [closeHandler])

    const keyDownHandler = useCallback((evt) => {
        if (evt.key === "Escape") closeModal()
    })

    useEffect(() => {
        document.addEventListener("keydown", keyDownHandler)
        return () => {
            document.removeEventListener("keydown", keyDownHandler)
        }
    }, [keyDownHandler])
    const modalClassNames = `
                        fixed
                        ${(darkTheme) ? "bg-dark" : "bg-white-A"}
                        z-10 w-full top-[59px] h-[calc(100%-59px)] left-0    
                    `

    const modal = <motion.div
        className={modalClassNames}
        variants={modalAnimations}
        initial="initial"
        animate="animate"
        exit="initial"
        id={props.html_id}>
        <div className="flex flex-col h-full pt-4 pb-4">
            <div className="flex align-baseline sm:pr-6 sm:pl-6 lg:pr-[78px] lg:pl-[78px]">
                <div className="font-semibold sm:text-3xl md:text-[42px] md:leading-[52.5px] text-primary dark:text-white-B flex-grow line-clamp-3">
                    {props.title}
                </div>
                <button className="h-fit" onClick={closeModal}>
                    <i className={
                        "dicruzcar-icon icon-close transition duration-300 ease-in text-black-B dark:text-white-B "
                    }></i>
                </button>
            </div>
            <div className="sm:flex sm:flex-col sm:flex-grow lg:grid-cols-4 mt-4 mb-2 space-y-4">
                {props.content}
            </div>
        </div>
    </motion.div>

    return <AnimatePresence>
        {(activeModal) ? modal : null}
    </AnimatePresence>
    
}

export default Modal